




import { Component, Vue } from 'vue-property-decorator';
import ContentPage from '@/views/contentPages/ContentPage.vue';

@Component({
  components: { ContentPage },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class PrivacyNotice extends Vue {

}
